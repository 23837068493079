<template>
  <div>
    <el-row :gutter="20" style="margin-top:30px">
      <el-col :span="12" style="margin-top:100px;">
        <div style="padding-left:10px">
          <div>
            <span style="color:#E8B457">{{ fundName }}</span> 行情数据：
          </div>
          <ECharts style="margin-left:10px" :heightCharts="500" :option="option"></ECharts>
        </div>

      </el-col>
      <el-col :span="12">
        <div style="height:500px;overflow: auto;">
          <!-- 帖子展示 -->
          <div v-for="(item, index) in discussList" :key="item.id">
            <!-- 帖子卡片 -->
            <el-card style="margin:0 10px;padding:0 18px">
              <el-row :gutter="20">
                <el-col :span="3">
                  <div class="main-left">
                    帖
                  </div>
                </el-col>

                <el-col :span="21">
                  <div class="content">
                    <el-icon :size="20" color="#3F53FB" style="position: relative;top:3px;right:5px">
                      <User />
                    </el-icon>
                    <span style="color:#F2B141">{{ item.createrName }}:</span>

                  </div>
                  <div style="font-size:14px;text-indent:28px;margin:0 0 10px 14px ">
                    {{ item.text }}
                  </div>
                </el-col>
              </el-row>
            </el-card>

            <!-- 帖子回复 -->
            <div class="reply" v-if="item.showReply">
              <div v-if="replyList.length > 0">
                <div v-for="itemChild in replyList" :key="itemChild.id" style="margin:5px 5px;font-size:13px;"
                  @click="openTextarea(itemChild)">
                  <div>
                    <span style="color:#F2B141">{{ itemChild.createrName }}:</span>
                    <span style="color:#565353;float: right;"> 回复</span>
                  </div>
                  <div style="font-size:12px;text-indent:24px;margin-left:5px">
                    <span>{{ itemChild.text }}:</span>
                  </div>
                </div>
                <div style="text-align: center;font-size: 13px;">
                  <p v-if="!loading && replyList.length <= replyListTatol" @click="load(item)">加载更多</p>
                  <p v-if="loading">加载中...</p>
                  <p v-if="replyList.length >= replyListTatol">暂无更多回复</p>
                </div>

              </div>

              <el-empty v-else description="暂无回复" :image-size="30" style="height:30px;"></el-empty>
            </div>
            <div style="margin:0 20px 15px 20px;font-size:15px;color:#606368">
              <span @click="lookReply(item)" v-if="item.showReply == false">
                ---展开回复<el-icon :size="15" style="position: relative;top:2px;left:3px">
                  <ArrowDownBold />
                </el-icon>
              </span>
              <span @click="closeReply" v-else>
                ---收起回复<el-icon :size="15" style="position: relative;top:2px;left:3px">
                  <ArrowUpBold />
                </el-icon>
              </span>
              <!-- 评论帖子 -->
              <el-icon :size="20" color="#606368" style="float:right;position: relative;top:2px;"
                @click="openTextarea(item)">
                <ChatLineSquare />
              </el-icon>
            </div>

          </div>
        </div>
        <!-- 分页 -->
        <div class="demo-pagination-block" style="float:right">
          <el-pagination v-model:current-page="currentPage" :page-sizes="[5, 10, 20]" :small="small" :disabled="disabled"
            layout="total,sizes, prev, pager, next" :total="discussListTotal" @size-change="handleSizeChange"
            @current-change="handleCurrentChange" />
        </div>

        <!-- 评论区 -->
        <div style="margin:20px 10px;clear: both;">
          <div style="color:#F2B141;margin-left:5px">
            {{ showTextarea ? '回帖' : '发帖' }}
          </div>
          <el-input type="textarea" maxlength="100" :rows="2" :placeholder="showTextarea ? replyName : '发帖'"
            v-model="textarea">
          </el-input>
          <div style="margin-top:5px">
            <span style="float:right;">
              <el-button v-if="showTextarea" type="primary" size="small" plain @click="closeComments">取消</el-button>
              <el-button type="success" size="small" plain @click="sendComments(showTextarea)">{{ showTextarea ? '回帖' : '发帖'
              }}</el-button>
            </span>
          </div>
        </div>

      </el-col>
    </el-row>

  </div>
</template>
<script>
import { onMounted } from 'vue'
import NavSonMenu from "@/components/NavSonMenu"//公用组件
import { reactive, toRefs, ref, computed } from "vue"
import { ElMessage } from "element-plus";
import { getNetWorthData, listDiscussionArea } from '../../forumApi.js'
import ECharts from '@/components/Echarts'
export default {
  components: {
    ECharts
  },
  props: {
    fundName: {
      type: String,
      default: '0'
    },
    fundId: {
      type: Number,
      default: 2
    },
  },
  setup(props) {
    onMounted(() => {
      // 获取当前用户名
      data.userName = JSON.parse(sessionStorage.getItem('user')).name;
      //净值图
      findFundTrend();
      //帖子
      //list()
    })

    let data = reactive({
      pid: 0,
      userName: '',
      // 回复区变量
      replyListTatol: 10,
      replyPageIndex: 1,
      loading: false,
      replyList: [
        { text: '开放式基金-1开放式基金-1开放式基金-1开放式基金-1开放式基金-1开放式基金-1开放式基金-1开放式基金-1开放式基金-1开放式基金-1', createrName: '学生1', id: '1' },
        { text: '开放式基金-2', createrName: '学生2', id: '2' },
        { text: '开放式基金-3', createrName: '学生3', id: '3' },
        { text: '开放式基金-4', createrName: '学生4', id: '4' },
        { text: '开放式基金-5', createrName: '学生5', id: '5' },
        { text: '开放式基金-6', createrName: '学生6', id: '6' },
        { text: '开放式基金-7', createrName: '学生7', id: '7' },
      ],
      // 分页页码
      currentPage: 1,
      discussListTotal: 100,
      showTextarea: false,
      textarea: '',
      showReply: false,
      // 被回复人名称
      replyName: '',
      discussList: [
        { text: '开放式基金-1开放式基金-1开放式基金-1开放式基金-1开放式基金-1开放式基金-1开放式基金-1开放式基金-1开放式基金-1开放式基金-1', createrName: '学生1', id: '1', showReply: false },
        { text: '开放式基金-2', createrName: '学生2', id: '2', showReply: false },
        { text: '开放式基金-3', createrName: '学生3', id: '3', showReply: false },
        { text: '开放式基金-4', createrName: '学生4', id: '4', showReply: false },
        { text: '开放式基金-5', createrName: '学生5', id: '5', showReply: false },
        { text: '开放式基金-6', createrName: '学生6', id: '6', showReply: false },
        { text: '开放式基金-7', createrName: '学生7', id: '7', showReply: false },
      ],
      //fundId:fundId,
      option: {}
    })

    // 查询行情数据
    const findFundTrend = () => {
      let date = [];
      let value = [];

      var formData = new FormData();
      formData.append("fundId", props.fundId);
      getNetWorthData(formData).then((res) => {
        let arr = res.data.data.fundTrendInformations
        if (res.data.code == 200) {
          date = res.data.data.date;
          value = res.data.data.value;
          data.option = {
            tooltip: {
              trigger: "axis",
            },
            xAxis: {
              type: "category",
              data: date,
            },
            yAxis: {
              type: "value",
            },
            series: [{
              data: value,
              type: "line",
              smooth: true,
              lineStyle: {
                color: "#FEAE10",
              },
              itemStyle: {
                color: "#FEAE10",
              },
            },],
          };
        }
      })
    }

    // 查询帖子回复
    const lookReply = (item) => {
      data.replyPageIndex = 1
      let object = {
        id: item.id,
        pageSize: 5,
        pageIndex: 1
      }
      console.log('item', item)
      data.discussList.forEach((item1) => {
        if (item1.id == item.id) {
          item1.showReply = true
        } else {
          item1.showReply = false
        }
      })
    }

    // 关闭回复
    const closeReply = () => {
      data.discussList.forEach((item) => {
        item.showReply = false
      })
    }

    // 打开回帖输入
    const openTextarea = (item) => {
      data.replyName = '回复：' + item.createrName
      data.showTextarea = true
    }

    // 取消回帖
    const closeComments = () => {
      data.showTextarea = false
      data.textarea = ''
    }

    // 发表评论或帖子
    const sendComments = (type) => {
      if (data.textarea.length < 1) {
        ElMessage.error('内容不能为空')
        return
      }
      data.textarea = ''
      if (type) {
        ElMessage.success('回复成功')
        closeComments()
      } else {
        ElMessage.success('发送成功')
      }
    }

    //分页 
    let handleSizeChange = (val) => {

    }
    let handleCurrentChange = (val) => {

    }

    // 回复内容分页加载数据
    const load = (item) => {
      console.log("item", item);
      let object = {
        id: item.id,
        pageSize: 5,
        pageIndex: (data.replyPageIndex + 1)
      }
      data.loading = true
      setTimeout(() => {
        data.replyListTatol == 20
        data.replyList.push(
          { text: '开放式基金-下一页', createrName: '学生-下一页', id: (Math.round(Math.random() * 1000) + 99) },
          { text: '开放式基金-下一页1', createrName: '学生-下一页1', id: (Math.round(Math.random() * 1000) + 99) },
          { text: '开放式基金-下一页2', createrName: '学生-下一页2', id: (Math.round(Math.random() * 1000) + 99) },
          { text: '开放式基金-下一页3', createrName: '学生-下一页3', id: (Math.round(Math.random() * 1000) + 99) },
          { text: '开放式基金-下一页4', createrName: '学生-下一页4', id: (Math.round(Math.random() * 1000) + 99) },
        )
        data.loading = false
      }, 1000)
    }


    const listPl = (item) => {
      let parm = {
        "fundId": props.fundId,
        "pid": value,
        "page": data.page2.currentPage,
        "pageSize": data.page2.pageSize,
        "rid": data.rid,
      };
      listDiscussionArea(parm).then(res => {
        data.plList2 = res.data.data.list;
        //如果评论小于5 不展示加载更多
        if (res.data.data.total > data.page2.pageSize) {
          data.showMore = true;
        } else {
          data.showMore = false;
        }
      })
    }




    return {
      ...toRefs(data),
      findFundTrend,
      lookReply,
      closeReply,
      openTextarea,
      closeComments,
      sendComments,
      handleSizeChange,
      handleCurrentChange,
      load,
      listPl
    }
  }
}
</script>
<style lang="less" scoped >
.assembly {
  margin: 20px 0px 10px
}

.main-left {
  font-family: 'FangSong';
  font-size: 30px;
  height: 60px;
  line-height: 60px;
  background: linear-gradient(to bottom, red, #5AC5D9);
  -webkit-background-clip: text;
  color: transparent;
}

&:deep(.el-card__body) {
  padding: 0px 0;
}

.content {
  height: 30px;
  line-height: 30px;
  margin-top: 5px
}

.reply {
  height: 200px;
  overflow: auto;
  margin: 0 11px;
  border: 1px dashed;
  border-radius: 0 0 10px 10px;
  border-top: none
}
</style>